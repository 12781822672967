import Vue from "vue/dist/vue.esm";

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter("titleize", function (value) {
  value = value.replace("_", " ");
  value = value.toLowerCase().split(" ");
  for (var i = 0; i < value.length; i++) {
    value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
  }
  return value.join(" ").replace(" Ppe", " PPE");
});
