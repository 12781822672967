import Vue from "vue/dist/vue.esm";
import VueResource from "vue-resource";
import VueCountryRegionSelect from "vue-country-region-select";
import "./filters.js";

Vue.use(VueResource);
Vue.use(VueCountryRegionSelect);

document.addEventListener("turbo:load", () => {
  Vue.http.headers.common["X-CSRF-Token"] = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  var element = document.getElementById("estimate_form");

  if (element != null) {
    element.classList.remove("d-none");
    document.getElementById("loading_estimate_form").classList.add("d-none");

    var id = element.dataset.slug;
    var estimate = JSON.parse(element.dataset.estimate);
    var estimate_items_attributes = JSON.parse(element.dataset.estimateItemsAttributes);
    var product_info = JSON.parse(element.dataset.productInfo);
    var domestic_methods = JSON.parse(element.dataset.domesticMethods);
    var international_methods = JSON.parse(element.dataset.internationalMethods);
    var discounts = JSON.parse(element.dataset.discounts);
    var estimate_fee_types = JSON.parse(element.dataset.estimateFeeTypes);
    var estimate_fees_attributes = JSON.parse(element.dataset.estimateFeesAttributes);

    estimate_items_attributes.forEach(function (item) {
      item._destroy = null;
    });
    estimate.estimate_items_attributes = estimate_items_attributes;

    estimate_fees_attributes.forEach(function (item) {
      item._destroy = null;
    });
    estimate.estimate_fees_attributes = estimate_fees_attributes;

    if (estimate.shipping_method == null) {
      estimate.shipping_method = domestic_methods[0];
    }

    if (estimate.country == null) {
      estimate.country = "US";
    }

    if (estimate.shipping_total != null) {
      estimate.shipping_total = Number(estimate.shipping_total);
    }

    if (estimate.discount_total != null) {
      estimate.discount_total = Number(estimate.discount_total);
    }

    var app = new Vue({
      el: element,
      data: {
        id: id,
        estimate: estimate,
        product_info: product_info,
        selected_detail: {},
        custom_qty_detail: null,
        global_qty: null,
        show_shipping: false,
        shipping_error: null,
        domestic_methods: domestic_methods,
        international_methods: international_methods,
        discounts: discounts,
        estimate_fee_types: estimate_fee_types,
        fee_type_selected: "",
        fee_per_unit: 0,
        fee_description: "",
        fee_quantity: 0,
      },
      watch: {
        country: function (countryVal) {
          if (countryVal == "US") {
            this.estimate.shipping_method = domestic_methods[0];
          } else {
            this.estimate.shipping_method = international_methods[0];
          }
        },
      },
      computed: {
        estimate_total: function () {
          var total = 0;
          var before_discount_total = 0;
          var free_packaging_total = 0;

          this.estimate.estimate_items_attributes.forEach(function (item) {
            if (item._destroy != "1") {
              if (estimate.free_packaging == true && item.view_options.is_packaging == true) {
                total += 0;
                before_discount_total += 0; // skip if free packaging?
                free_packaging_total += item.quantity * item.unit_price;
              } else {
                before_discount_total += item.quantity * item.unit_price;
                if (estimate.percent_discount != null) {
                  total += (Math.round((1 - estimate.percent_discount) * item.unit_price * 100) / 100).toFixed(2) * item.quantity;
                } else {
                  total += item.quantity * item.unit_price;
                }
              }
            }
          });

          this.estimate.discount_total = before_discount_total - total;
          this.estimate.free_packaging_total = free_packaging_total;

          if (this.estimate.shipping_total != null) {
            total += this.estimate.shipping_total;
          }
          this.estimate.estimate_fees_attributes.forEach(function (estimate_fee) {
            if (estimate_fee._destroy != "1") {
              total += estimate_fee.quantity * estimate_fee.fee;
            }
          });
          return total;
        },
        selected_product_info_counts: function () {
          // We can assume the index of this array relates to the same index in estimate_items_attributes
          var ids = {};
          this.estimate.estimate_items_attributes.forEach(function (item) {
            ids[item.product_info_id] = (ids[item.product_info_id] || 1) + 1;
          });
          return ids;
        },
        country: function () {
          return this.estimate.country;
        },
        flat_product_info: function () {
          // flattens product_info to remove the categories
          return Object.values(this.product_info).flat();
        },
        best_sellers: function () {
          var best_sellers = [];
          this.flat_product_info.forEach((p_info) => {
            if (p_info.best_seller != null && p_info.custom_design == null) {
              best_sellers[best_sellers.length] = p_info;
            }
          });
          best_sellers.sort((a, b) => (a.best_seller > b.best_seller ? 1 : -1));
          return best_sellers;
        },

        custom_designs_with_categories: function () {
          var custom_designs_with_categories = {};
          Object.entries(this.product_info).forEach(([key, value]) => {
            custom_designs_with_categories[key] = [];
            value.forEach((product_data) => {
              if (product_data.custom_design == true) {
                custom_designs_with_categories[key].push(product_data);
              }
            });
          });
          return custom_designs_with_categories;
        },

        categories: function () {
          var output = [];
          if (this.best_sellers.length > 0) {
            output.push("best_sellers");
          }
          return output.concat(Object.keys(this.product_info));
        },
      },
      methods: {
        setCustomQty: function (event) {
          this.custom_qty_detail = event.target.value;
        },
        clearGlobalQty: function (event) {
          this.global_qty = null;
          this.updateWithGlobalQty();
        },
        clearShippingTotal: function () {
          this.estimate.shipping_total = null;
        },

        addEstimateDiscount: function (key, percent) {
          if (percent < 1) {
            this.estimate.percent_discount = percent;
          } else if (percent == 1.0) {
            if (key == "free_shipping") {
              this.estimate.free_shipping = true;
              this.estimate.shipping_total = null;
            } else if (key == "free_packaging") {
              this.estimate.free_packaging = true;
            }
          }
        },

        addEstimateFee: function () {
          var fee_name = Object.keys(this.estimate_fee_types).find((key) => this.estimate_fee_types[key] === this.fee_type_selected);

          this.estimate.estimate_fees_attributes.push({
            qb_item_id: this.fee_type_selected,
            fee_type: fee_name,
            fee: this.fee_per_unit,
            description: this.fee_description,
            quantity: this.fee_quantity,
            _destroy: null,
          });
        },

        // updated to get correct product info in estimate summary/cart when editing saved estimate
        getPInfoFromEstimateItem: function (estimate_item_id, mockup_id = null, packaging_id = null, product_id = null, sock_pair_id = null) {
          if (mockup_id) {
            return this.flat_product_info.find((p_info) => p_info.mockup_id == mockup_id && p_info.custom_design == true);
          } else if (packaging_id) {
            return this.flat_product_info.find((p_info) => p_info.packaging_id == packaging_id && p_info.custom_design == true);
          } else if (product_id) {
            return this.flat_product_info.find((p_info) => p_info.product_id == product_id && p_info.custom_design == true);
          } else if (sock_pair_id) {
            return this.flat_product_info.find((p_info) => p_info.sock_pair_id == sock_pair_id && p_info.custom_design == true);
          } else {
            return this.flat_product_info.find((p_info) => p_info.product_info_id == estimate_item_id && p_info.custom_design == null);
          }
        },

        getPInfoFromEstimateItemForSummary: function (estimate_item) {
          return this.getPInfoFromEstimateItem(
            estimate_item.product_info_id,
            estimate_item.mockup_id,
            estimate_item.packaging_id,
            estimate_item.product_id,
            estimate_item.sock_pair_id
          );
        },

        // to get correct image path in estimate summary/cart when editing saved estimate
        getImagePathFromEstimateItem: function (estimate_item) {
          var get_correct_product_info_for_image = this.getPInfoFromEstimateItem(
            estimate_item.product_info_id,
            estimate_item.mockup_id,
            estimate_item.packaging_id,
            estimate_item.product_id,
            estimate_item.sock_pair_id
          );
          return get_correct_product_info_for_image.product_img_path;
        },

        getCustomEstimateItem: function (product_data) {
          var custom_estimate_item = this.estimate.estimate_items_attributes.find(
            (estimate_item) =>
              product_data.custom_design == true &&
              product_data.mockup_id == estimate_item.mockup_id &&
              product_data.packaging_id == estimate_item.packaging_id &&
              product_data.product_id == estimate_item.product_id &&
              product_data.sock_pair_id == estimate_item.sock_pair_id &&
              product_data.product_info_id == estimate_item.product_info_id
          );
          return custom_estimate_item;
        },

        checkCustomEstimateItemIsAdded: function (product_data) {
          // should only be able to add custom designed product once to estimate cart
          var is_already_added = false;
          var custom_estimate_item = this.getCustomEstimateItem(product_data);
          if (custom_estimate_item) {
            is_already_added = true;
          }
          return is_already_added;
        },

        // qty is optional, will default to lowest qty price break
        addEstimateItem: function (product_data, qty = null) {
          var min_qty = Object.keys(product_data.price_set)[0];
          var quantity = qty || product_data.display_qty || min_qty;
          var design_number = 1;

          // prevent adding a new estimate_item for custom designs when clicking on image in estimate cart or
          // View Details and updating quantity in details modal (_modal_details partial)
          // leaving non-custom designs unaffected so that a customer can add another non-custom product to estimate
          // cart via the details modal
          let custom_estimate_item = this.getCustomEstimateItem(product_data);

          if (custom_estimate_item) {
            var min_qty = Object.keys(product_data.price_set)[0];
            if (qty != null && qty >= product_data.display_qty && custom_estimate_item.quantity != qty) {
              custom_estimate_item.quantity = qty;
              custom_estimate_item.unit_price = this.calculatePrice(custom_estimate_item.view_options.price_set, qty);
              custom_estimate_item.view_options.edit_qty = false;
              this.estimate.shipping_total = null;
            }
          } else {
            if (product_data.custom_design == true) {
              design_number = product_data.design_number;
            } else {
              design_number = this.selected_product_info_counts[product_data.product_info_id] || 1;
            }

            if (Number(min_qty) > Number(quantity)) {
              quantity = min_qty;
            }

            this.estimate.estimate_items_attributes.push({
              product_info_id: product_data.product_info_id,
              packaging_id: product_data.packaging_id,
              product_id: product_data.product_id,
              sock_pair_id: product_data.sock_pair_id,
              quantity: quantity,
              mockup_id: product_data.mockup_id,
              custom_design: product_data.custom_design,
              unit_price: this.calculatePrice(product_data.price_set, quantity),
              // NOTE updating view_options here need to be updated in estimate_items_attributes_for_vue so edit works
              view_options: {
                product_name: product_data.product_name,
                min_qty: min_qty,
                edit_qty: false,
                price_set: product_data.price_set,
                product_img_path: product_data.product_img_path,
                design_number: design_number,
                product_type: product_data.product_type,
                is_packaging: product_data.is_packaging,
              },
              _destroy: null,
            });
            this.custom_qty_detail = null;
            this.estimate.shipping_total = null;
          }
        },

        calculatePrice: function (price_set, quantity) {
          // TODO validate min qty.
          var previous = -1;
          var index;
          for (index in price_set) {
            var qty_break = parseInt(index);
            if (previous != -1 && quantity < qty_break) {
              return Number(price_set[previous]);
            } else {
              previous = qty_break;
            }
          }
          // return highest price break, when quantity > the last break.
          return Number(price_set[previous]);
        },

        editQuantity: function (index, new_qty = null) {
          var item = this.estimate.estimate_items_attributes[index];

          if (new_qty == null) {
            new_qty = event.target.value;
          }

          if (Number(item.view_options.min_qty) > Number(new_qty)) {
            new_qty = item.view_options.min_qty;
          }

          item.quantity = new_qty;
          item.unit_price = this.calculatePrice(item.view_options.price_set, new_qty);
          item.view_options.edit_qty = false;
          this.estimate.shipping_total = null;
        },

        removeEstimateItem: function (index) {
          var item = this.estimate.estimate_items_attributes[index];
          var item_product_info_id = item.product_info_id;

          if (item.id == null) {
            this.estimate.estimate_items_attributes.splice(index, 1);
          } else {
            this.estimate.estimate_items_attributes[index]._destroy = "1";
          }

          // update the design numbers
          var new_design_num_count = 1;
          this.estimate.estimate_items_attributes.forEach(function (item) {
            if (item.product_info_id == item_product_info_id && item.custom_design == null) {
              item.view_options.design_number = new_design_num_count;
              new_design_num_count++;
            }
          });
          this.estimate.shipping_total = null;
        },

        removeEstimateFee: function (index) {
          var estimate_fee = this.estimate.estimate_fees_attributes[index];

          if (estimate_fee.id == null) {
            this.estimate.estimate_fees_attributes.splice(index, 1);
          } else {
            this.estimate.estimate_fees_attributes[index]._destroy = "1";
          }
        },

        undoRemoveEstimateItem: function (index) {
          this.estimate.estimate_items_attributes[index]._destroy = null;
          this.estimate.shipping_total = null;
        },
        undoRemoveEstimateFee: function (index) {
          this.estimate.estimate_fees_attributes[index]._destroy = null;
        },

        removeEstimatePercentDiscount: function () {
          this.estimate.discount_total = null;
          this.estimate.percent_discount = null;
        },

        removeEstimateFreeShippingDiscount: function () {
          this.estimate.free_shipping = null;
        },

        removeEstimateFreePackagingDiscount: function () {
          this.estimate.free_packaging = null;
        },

        saveEstimate: function () {
          // disable button
          var saveBtn = document.getElementById("save_estimate_btn");
          saveBtn.disabled = true;
          saveBtn.innerText = "Saving...";

          // remove the view options from the estimate_items_attributes
          this.estimate.estimate_items_attributes.forEach(function (item) {
            delete item.view_options;
          });

          // clear shipping data if no total exists
          if (Number(this.estimate.shipping_total) == 0) {
            this.estimate.shipping_total = null;
            this.estimate.shipping_method = null;
            this.estimate.state = null;
            this.estimate.country = null;
            this.estimate.zip_code = null;
          }

          // create new estimate
          if (this.id == null) {
            if (typeof posthog !== "undefined") {
              posthog.capture("Client Created Quote");
            }
            this.$http.post($('meta[name="RELATIVE_URL_ROOT"]').attr("content") + "/estimates", { estimate: this.estimate }).then(
              (response) => {
                window.location = $('meta[name="RELATIVE_URL_ROOT"]').attr("content") + `/estimates/${response.body.slug}`;
              },
              (error) => {
                // TODO handle error
              }
            );

            // edit existing estimate
          } else {
            this.$http.put($('meta[name="RELATIVE_URL_ROOT"]').attr("content") + `/estimates/${this.id}`, { estimate: this.estimate }).then(
              (response) => {
                window.location = $('meta[name="RELATIVE_URL_ROOT"]').attr("content") + `/estimates/${response.body.slug}`;
              },
              (error) => {
                // TODO handle error
              }
            );
          }
        },

        updateWithGlobalQty: function () {
          // go through each product, if the global_qty > min qty should pricebreak @ global_qty
          this.flat_product_info.forEach((p_info) => {
            var min_qty = Object.keys(p_info.price_set)[0];
            if (Number(min_qty) > Number(this.global_qty)) {
              p_info.display_qty = min_qty;
              p_info.showing_min = true;
            } else {
              p_info.display_qty = Number(this.global_qty);
              p_info.showing_min = false;
            }
          });
        },

        calculateShipping: function () {
          if (this.estimate.free_shipping == true) {
            this.shipping_error = "Shipping is Free";
            return;
          }
          // validate shipping fields
          if (this.estimate.zip_code.length == 0 || this.estimate.zip_code == null) {
            this.shipping_error = "Zipcode is required";
            return;
          }
          if (this.estimate.country == "CA" && (this.estimate.state.length == 0 || this.estimate.state.length == null)) {
            this.shipping_error = "Region is required";
            return;
          }

          var submitBtn = document.getElementById("estimate_shipping_btn");

          submitBtn.disabled = true;
          submitBtn.innerText = "Calculating...";
          this.$http.post($('meta[name="RELATIVE_URL_ROOT"]').attr("content") + "/calculate_shipping_estimate", { estimate: this.estimate }).then(
            (response) => {
              this.estimate.shipping_total = Number(response.body.shipping_cost);
              this.shipping_error = null;
              this.show_shipping = false;
              submitBtn.disabled = false;
              submitBtn.innerText = "Calculate";
            },
            (error) => {
              this.estimate.shipping_total = 0;
              this.shipping_error = "Shipping estimate could not be calculated for this location and shipping method.";
              submitBtn.disabled = false;
              submitBtn.innerText = "Calculate";
            }
          );
        },
      },
    });
  }
});
